import React, { useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider } from '@material-ui/core';

const useConfirm = (title, message) => {
    const [promise, setPromise] = useState(null);

    const confirm = () => new Promise((resolve, reject) => {
        setPromise({ resolve });
    });

    const handleClose = () => {
        setPromise(null);
    };

    const handleConfirm = () => {
        if (promise) promise.resolve(true);
        handleClose();
    };

    const handleCancel = () => {
        if (promise) promise.resolve(false);
        handleClose();
    };

    const ConfirmationDialog = () => (
        <Dialog
            open={promise !== null}
            fullWidth
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <DialogContentText style={{ fontWeight: '400', fontSize: 14 }}>{message}</DialogContentText>
            </DialogContent>
            <Divider />
            <DialogActions>
                <Button
                    style={{
                        backgroundColor: '#113b61',
                        width: 120,
                        border: 'none',
                        borderRadius: '4px',
                        color: '#ffffff',
                        cursor: 'pointer',
                        fontFamily: 'Roboto',
                    }}
                    variant="contained"
                    color="secondary"
                    type="button"
                    onClick={handleConfirm}>Confirmar</Button>
                <Button
                    style={{
                        backgroundColor: 'red',
                        width: 120,
                        border: 'none',
                        borderRadius: '4px',
                        color: '#ffffff',
                        cursor: 'pointer',
                        fontFamily: 'Roboto',
                    }}
                    variant="contained"
                    color="primary"
                    type="button"
                    onClick={handleCancel}>Cancelar</Button>
            </DialogActions>
        </Dialog>
    );
    return [ConfirmationDialog, confirm];
};

export default useConfirm;