import React, { useEffect, useRef, useState } from "react";
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/toolbar/lib/styles/index.css';

import ActionButtons from "./ActionButtons";
import { BASE_URI } from "../App";

const ViewContract = (props) => {
  const [width, setWidth] = useState(window.innerWidth);
  const { beneficiario, parts } = props;
  const refIframe = useRef(null);

  useEffect(() => {
    const uri = `https://docs.google.com/viewer?url=${BASE_URI}${beneficiario.contratoOriginal.replace('.pdf', (parts[1] === 'vendedor') ? '-assinado-beneficiario.pdf' : '.pdf')}&embedded=true`;
    console.log(uri);
    refIframe.current.setAttribute(
      'src',
      uri,
    );
  }, []);

  return (
    <div style={{
      width: width < 600 ? '100%' : '60%',
      backgroundColor: 'white'
    }}>
      {beneficiario && beneficiario.contratoOriginal ? (
        <iframe
          ref={refIframe}
          // src={`https://docs.google.com/viewer?url=${BASE_URI}${beneficiario.contratoOriginal.replace('.pdf', (parts[1] === 'vendedor') ? '-assinado-beneficiario.pdf' : '.pdf')}&embedded=true`}
          width={width < 600 ? '100%' : '60%'}
          height={width < 600 ? 500 : 600}
          allow="autoplay"
          title={beneficiario.nomeFantasia}
          style={{
            display: 'block',
            margin: '0 auto',
          }}
        />
      ) : (<div></div>)}
      <ActionButtons {...props} />
    </div>
  );
};

export default ViewContract;