import React, { useCallback, useRef, useState } from "react";
import { FormGroup, Label, Button, Modal, ModalBody, ModalFooter } from "reactstrap";
import Webcam from "react-webcam";
import ActionButtons from "./ActionButtons";
import { Snackbar } from "@material-ui/core";
import { Alert } from '@material-ui/lab';

const DocumentPhoto = (props) => {
    const [width, setWidth] = useState(window.innerWidth);
    const { beneficiario, setPhotoCapture, parts } = props;
    const [isTaking, setIsTaking] = useState(false);
    const [screenShot, setScreenShot] = useState(false);
    const [messageState, setMessageState] = useState(false);
    const webcamRef = useRef(null);

    const capture = useCallback(
        () => {
            const imageSrc = webcamRef.current.getScreenshot();
            setScreenShot(imageSrc);
            setPhotoCapture(imageSrc);
            setIsTaking(false);
        },
        [webcamRef]
    );

    const handleClose = () => {
        setMessageState(false);
    };

    const handleValidadePhoto = () => {
        if (!screenShot) {
            setMessageState(true);
        }
        else {
            props.nextStep();
        }
    };

    if (!beneficiario) return (<div></div>);
    return (
        <>
            <div style={{
                padding: 20,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center'
            }}>
                <div>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}>
                        <div style={{ textAlign: 'center' }}>
                            <p><b>{(parts[1] === 'vendedor') ? beneficiario._vendedor.nomeFantasia : beneficiario.nomeFantasia}</b>, tire uma foto segurando seu documento.</p>
                            <p>Sua foto será protegida por marca d'água.</p>
                            <p>O documento pode ser seu RG ou CNH, ou outro documento oficial.</p>
                            <p>Tenha cuidado para não cobrir o rosto com o documento.</p>
                        </div>
                        <div style={{ width: 220 }}>
                            <img
                                style={{
                                    height: 'auto',
                                    width: 220,
                                    objectFit: 'contain',
                                }}
                                src={require('../assets/photo_image.jpeg')} />
                        </div>
                        {screenShot && (
                            <div style={{ padding: 20, width: 400 }}>
                                <img
                                    style={{
                                        height: 'auto',
                                        width: 400,
                                        objectFit: 'contain',
                                    }}
                                    src={screenShot} />
                            </div>
                        )}
                        <Button
                            style={{
                                marginTop: 10,
                                width: 220,
                                height: 45,
                                border: 'none',
                                borderRadius: '4px',
                                color: '#ffffff',
                                cursor: 'pointer',
                                padding: '2px',
                                fontFamily: 'Roboto',
                                backgroundColor: '#0693e3',
                                cursor: 'pointer'
                            }}
                            onClick={() => {
                                setScreenShot(null);
                                setIsTaking(true);
                            }}>Tirar uma foto</Button>
                    </div>
                    <ActionButtons {...props} nextStep={handleValidadePhoto} />
                </div>
            </div>
            <Modal
                isOpen={isTaking}
                keyboard={false}
                backdrop={true}
                centered={true}
            >
                <ModalBody style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: 'white',
                }}>
                    <Webcam
                        ref={webcamRef}
                        audio={false}
                        height={350}
                        width={380}
                        screenshotFormat="image/jpeg"
                        videoConstraints={{
                            width: 380,
                            height: 350,
                            facingMode: "user"
                        }}
                    />
                </ModalBody>
                <ModalFooter>
                    <Button
                        style={{
                            width: 140,
                            border: 'none',
                            borderRadius: '4px',
                            color: '#ffffff',
                            cursor: 'pointer',
                            padding: '8px',
                            fontFamily: 'Roboto',
                            backgroundColor: '#113b61',
                            cursor: 'pointer',
                        }}
                        color="primary" onClick={() => {
                            capture();
                        }}>
                        Tirar Foto
                    </Button>{' '}
                    <Button
                        style={{
                            width: 140,
                            border: 'none',
                            borderRadius: '4px',
                            color: '#ffffff',
                            cursor: 'pointer',
                            padding: '8px',
                            fontFamily: 'Roboto',
                            backgroundColor: 'red',
                            cursor: 'pointer',
                        }}
                        color="secondary" onClick={() => {
                            setIsTaking(false);
                        }}>
                        Cancelar
                    </Button>
                </ModalFooter>
            </Modal>
            <Snackbar open={messageState} autoHideDuration={6000} onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert
                    onClose={handleClose}
                    severity="error"
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    Necessário capturar uma foto com documento em mãos.
                </Alert>
            </Snackbar>
        </>
    );
};

export default DocumentPhoto;