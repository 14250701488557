import React, { useState } from "react";
import { Stepper, Step } from "react-form-stepper";
import { MdDescription } from "react-icons/md";
import { MdContactPhone } from "react-icons/md";
import { MdAddAPhoto } from "react-icons/md";
import { MdAutofpsSelect } from "react-icons/md";
import StepWizard from "react-step-wizard";

import ViewContract from "./components/Contract";
import PersonalData from "./components/personalData";
import DocumentPhoto from "./components/PhotoDocument";
import ContractSignature from "./components/ContractSignature";
import ViewTelemedicine from "./components/ViewTelemedicine";
import ViewNoFuneral from "./components/ViewNoFuneral";

const Sample = ({ beneficiario, parts }) => {
  const [stepWizard, setStepWizard] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const [photoCapture, setPhotoCapture] = useState(null);

  const assignStepWizard = (instance) => {
    setStepWizard(instance);
  };

  const handleStepChange = (e) => {
    setActiveStep(e.activeStep - 1);
  };

  const handleComplete = () => {
    alert("You r done. TQ");
  };

  return (
    <div style={{ width: '100%' }}>
      <Stepper activeStep={activeStep}>
        <Step label="" children={<MdDescription />} />
        {beneficiario && beneficiario.telemedicina === 'S' && <Step label="" children={<MdDescription />} />}
        {/* <Step label="Não Funeral" children={<MdDescription />} /> */}
        <Step label="" children={<MdContactPhone />} />
        <Step label="" children={<MdAddAPhoto />} />
        <Step label="" children={<MdAutofpsSelect />} />
      </Stepper>
             {/* <ViewNoFuneral beneficiario={beneficiario} /> */}
      <StepWizard instance={assignStepWizard} onStepChange={handleStepChange}>
        <ViewContract beneficiario={beneficiario} parts={parts} />
        {beneficiario && beneficiario.telemedicina === 'S' && <ViewTelemedicine beneficiario={beneficiario} parts={parts} />}
        <PersonalData beneficiario={beneficiario} parts={parts}/>
        <DocumentPhoto beneficiario={beneficiario} setPhotoCapture={setPhotoCapture} parts={parts} />
        <ContractSignature beneficiario={beneficiario} photoCapture={photoCapture} completeCallback={handleComplete} parts={parts} />
      </StepWizard>
    </div>
  );
};

export default Sample;
