import React from 'react';

import { useCountdown } from './hooks/useCountdown';
import DateTimeDisplay from './DateTimeDisplay';

const ExpiredNotice = ({ resendToken }) => {
    return (
        <div className="expired-notice">
            <button
                onClick={() => {
                    resendToken();
                }}
                style={{
                    width: 220,
                    height: 45,
                    backgroundColor: '#113b61',
                    border: 'none',
                    borderRadius: '4px',
                    color: '#ffffff',
                    cursor: 'pointer',
                    padding: '2px',
                    fontFamily: 'Roboto',
                }}>Reenviar o Token!</button>
        </div>
    );
};

const ShowCounter = ({ days, hours, minutes, seconds }) => {
    return (
        <div className="show-counter">
            <div className="countdown-link">
                <DateTimeDisplay value={hours} type={'Hours'} isDanger={false} />
                <span>:</span>
                <DateTimeDisplay value={minutes} type={'Mins'} isDanger={false} />
                <span>:</span>
                <DateTimeDisplay value={seconds} type={'Seconds'} isDanger={false} />
            </div>
        </div>
    );
};

const CountdownTimer = ({ targetDate, resendToken }) => {
    const [days, hours, minutes, seconds] = useCountdown(targetDate);
    if (minutes + seconds <= 0) {
        return <ExpiredNotice resendToken={resendToken} />;
    } else {
        return (
            <ShowCounter
                days={days}
                hours={hours}
                minutes={minutes}
                seconds={seconds}
            />
        );
    }
};

export default CountdownTimer;