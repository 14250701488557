import React, { useState } from "react";
import { Row, Col, Button } from "reactstrap";

const ActionButtons = (props) => {
    const [width, setWidth] = useState(window.innerWidth);

    const handleBack = () => {
        props.previousStep();
    };

    const handleNext = () => {
        props.nextStep();
    };

    const handleFinish = () => {
        props.lastStep();
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'row', marginTop: 10, justifyContent: 'center' }}>
            <Row style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                paddingTop: 10,
                paddingBottom: 10,
            }}>
                {props.currentStep > 1 && (
                    <Col>
                        <Button
                            style={{
                                width: width < 600 ? 160 : 220,
                                backgroundColor: '#113b61',
                                border: 'none',
                                borderRadius: '4px',
                                color: '#ffffff',
                                cursor: 'pointer',
                                padding: '8px',
                                fontFamily: 'Roboto',
                                backgroundColor: '#113b61',
                                cursor: 'pointer',
                            }}
                            onClick={handleBack}>Voltar</Button>
                    </Col>
                )}
                <Col>
                    {props.currentStep < props.totalSteps && (
                        <Button
                            style={{
                                width: width < 600 ? 160 : 220,
                                backgroundColor: '#113b61',
                                border: 'none',
                                borderRadius: '4px',
                                color: '#ffffff',
                                cursor: 'pointer',
                                padding: '8px',
                                fontFamily: 'Roboto',
                                backgroundColor: '#113b61',
                                cursor: 'pointer',
                            }}
                            onClick={handleNext}>Avançar</Button>
                    )}
                    {props.currentStep === props.totalSteps && (
                        <Button
                            style={{
                                width: 220,
                                backgroundColor: '#113b61',
                                border: 'none',
                                borderRadius: '4px',
                                color: '#ffffff',
                                cursor: 'pointer',
                                padding: '8px',
                                fontFamily: 'Roboto',
                                backgroundColor: '#113b61',
                                cursor: 'pointer',
                            }}
                            onClick={handleFinish}>Confirmar Assinatura</Button>
                    )}
                </Col>
            </Row>
        </div>
    );
};

export default ActionButtons;