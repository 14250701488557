
import React, { useEffect, useRef, useState } from "react";
import ReactLoading from "react-loading";
import axios from "axios";
import html2canvas from "html2canvas";

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/toolbar/lib/styles/index.css';

import WbAutoIcon from '@material-ui/icons/WbAuto';
import GestureIcon from '@material-ui/icons/Gesture';
import SignatureCanvas from 'react-signature-canvas';
import "../styles.css";

import OtpInput from 'react-otp-input';
import { MenuItem, Select, Snackbar } from "@material-ui/core";
import { Alert } from '@material-ui/lab';

import useConfirm from "../hooks/useConfirm";
import CountdownTimer from "../CountdownTimer";

import { BASE_URI, fonts, headers } from "../App";
import { Modal, ModalBody } from "reactstrap";

const generateOtp = () => {
  let generatedOtp = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  for (let i = 0; i < 6; i++) {
    generatedOtp += characters
      .charAt(Math
        .floor(Math.random() * characters.length));
  }
  return generatedOtp.toUpperCase();
};

const toCamelCase = (str) => {
  const palavras = str.split(" ");
  for (let i = 0; i < palavras.length; i++) {
    if (palavras[i].length > 2) {
      palavras[i] = palavras[i][0].toUpperCase() + palavras[i].substr(1).toLowerCase();
    } else {
      palavras[i] = palavras[i].toLowerCase();
    }
  }
  return palavras.join(" ");
}

const ContractSignature = (props) => {
  const { beneficiario, photoCapture, parts } = props;

  const [state, setState] = useState({
    beneficiario: null,
    trimmedDataURL: null,
    isDrawSignature: true,
    selectedFont: 'tangerineRegular',
    imageSignature: '',
    isSendedToken: false,
    isSigned: false,
    isSendingEmail: false,
  });
  const [token, setToken] = useState(null);
  const [otp, setOtp] = useState('');
  const [signature, setSignature] = useState(null);
  const [messageState, setMessageState] = useState(false);
  const [dateTimeAfterThreeDays, setDateTimeAfterThreeDays] = useState(0);
  const sigPad = useRef(null);
  const previewRef = useRef(null);

  const THREE_DAYS_IN_MS = useRef(30 * 1000);

  const [DialogSignature, confirmSignature] = useConfirm(
    "Atenção!!",
    "Confirma Assinatura do Contrato?"
  );

  useEffect(() => {
    if (previewRef.current) {
      html2canvas(previewRef.current, {
        backgroundColor: null,
      }).then((canvas) => {
        const _signature = canvas.toDataURL("image/png");
        setSignature(_signature)
      });
    }
  }, [previewRef.current, state.isDrawSignature]);

  const handleSendEMAIL = (beneficiario, _token) => {

    try {

      const ddd = `${(parts[1] === 'vendedor') ? beneficiario._vendedor.ddd : beneficiario.ddd}`;
      const celular = `${(parts[1] === 'vendedor') ? beneficiario._vendedor.celular : beneficiario.celular}`;
      const email = `${(parts[1] === 'vendedor') ? beneficiario._vendedor.email : beneficiario.email}`;

      const data = JSON.stringify({
        telefone: `${ddd.trim().replace('0', '')}${celular.trim().split(' ').join('').split('-').join('')}`,
        email,
        token: _token
      });

      axios({
        method: "post",
        url: `${BASE_URI}/api/assinatura/token`,
        data,
        headers: headers,
      }).then((response) => {
        setState((prev) => ({ ...prev, isSendingEmail: false }));
        if (response && response.data) {
          const { enviado } = response.data;
          if (enviado) {
            setState((prev) => ({ ...prev, isSendedToken: enviado }));
            const NOW_IN_MS = new Date().getTime();
            THREE_DAYS_IN_MS.current += (10 * 1000);
            setDateTimeAfterThreeDays(NOW_IN_MS + THREE_DAYS_IN_MS.current);
          }
        }
      }).catch((error) => {
        setState((prev) => ({ ...prev, isSendingEmail: false }));
        throw (error);
      });
    } catch (error) {
      setState((prev) => ({ ...prev, isSendingEmail: false }));
      console.log(error);
    }

  }

  function readFileAsync(file, sufix) {
    return new Promise(async (resolve, reject) => {
      const path = beneficiario.cpf.split(".").join("").replace("-", "");
      if (!file) {
        setState((prev) => ({ ...prev, isSendingEmail: false }));
        return resolve({});
      }

      const formData = new FormData();
      formData.append("file", file, `${(parts[1] === 'vendedor') ? beneficiario.vendedor : beneficiario.codigo}_${sufix}.jpg`);

      try {
        const response = await axios({
          method: "post",
          url: `${BASE_URI}/api/pushFile/${path}`,
          data: formData,
          headers: headers,
        });
        if (response && response.data) {
          const { file } = response.data;
          return resolve({ ...file });
        } else {
          setState((prev) => ({ ...prev, isSendingEmail: false }));
          return resolve({});
        }
      } catch (error) {
        setState((prev) => ({ ...prev, isSendingEmail: false }));
      }
      return resolve({});
    });
  }

  const clear = () => {
    sigPad.current.clear();
    setSignature(null);
  }

  const confirmar = async (beneficiario) => {
    let ans = await confirmSignature();
    if (ans) {
      setState((prev) => ({ ...prev, isSendingEmail: true }));
      const _token = generateOtp();
      setToken(_token);
      handleSendEMAIL(beneficiario, _token);
    }
  }

  const handleSelectClass = (e) => {
    const { value } = e.target;
    setState((prev) => ({ ...prev, selectedFont: value }));
  }

  const confirmarAssinatura = (assinatura, documento) => {
    return new Promise(async (resolve, reject) => {
      try {

        const mutation = `
          mutation($input: CreateContratoStatus!) {
            saveContratoStatus(input: $input) {
              codigo
            }
          }
        `
        const query = {
          query: mutation,
          variables: {
            "input": {
              "codigo": beneficiario.codigo,
              "contratoStatus": (parts[1] === 'liberty') ? 98 : (parts[1] === 'vendedor') ? 5 : 3,
              "assinaturaBeneficiario": assinatura,
              "fotoDocumentoAssinatura": documento,
              "assinaturaVendedor": assinatura,
              "fotoDocumentoVendedor": documento,
            }
          }
        }

        const response = await axios({
          url: `${BASE_URI}/graphql`,
          method: 'post',
          headers: headers,
          data: query
        }).then((res) => res.data);

        return resolve(response);

      } catch (error) {
        return reject(error);
      }
    })
  }

  const confirmarToken = async () => {
    if (otp === token) {
      setState((prev) => ({ ...prev, isSendingEmail: true }));
      let file = await fetch(signature).then(res => res.blob());
      const _fileSignature = await readFileAsync(file, 'assinatura');

      file = await fetch(photoCapture).then(res => res.blob());
      const _filePhotograph = await readFileAsync(file, 'fotografia');

      if (_fileSignature && _fileSignature.filename && _filePhotograph && _filePhotograph.filename) {
        confirmarAssinatura(_fileSignature.filename, _filePhotograph.filename)
          .then((response) => {
            setState((prev) => ({ ...prev, isSendingEmail: false }));
            const { saveContratoStatus } = response.data;
            if (saveContratoStatus && saveContratoStatus.codigo) {
              setState((prev) => ({ ...prev, isSigned: true }));
            }
          })
          .catch((error) => {
            setState((prev) => ({ ...prev, isSendingEmail: false }));
            console.log(error);
          });
      }
    } else {
      setMessageState(true);
    }
  }

  const resendToken = () => {
    setState((prev) => ({ ...prev, isSendingEmail: true }));
    const _token = generateOtp();
    setToken(_token);
    handleSendEMAIL(beneficiario, _token);
  }

  const handleClose = () => {
    setMessageState(false);
  };

  if (!beneficiario) return (<div></div>);

  if (state.isSigned) {
    return (
      <div style={{
        padding: 10,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}>
        <span><b>Sua assinatura foi concluída com Sucesso!</b></span>
        <span>Você receberá uma cópia do seu contrato por e-mail, assim que o todos os participantes tiverem finalizado sua assinatura.</span>
        <span>Uma cópia deste contrato, foi enviado em seu e-mail {(parts[1] === 'vendedor') ? beneficiario._vendedor.email : beneficiario.email}!</span>
      </div>
    )
  }

  return (
    <div>
      <div style={{
        display: 'flex',
        flexDirection: 'column',
      }}>
        {state.isSendedToken && <div className="title-signature" style={{
          flexDirection: 'column',
          alignItems: 'center',
          padding: 10,
        }}>
          <div style={{ fontFamily: 'Roboot', fontSize: 16, padding: 10, textAlign: 'center' }}>
            Por favor, insira o Token de 6 digitos que foi enviado no email abaixo
          </div>
          <div style={{ fontFamily: 'Roboot', fontSize: 16, marginBottom: 20 }}>
            {(parts[1] === 'vendedor') ? beneficiario._vendedor.email : beneficiario.email}
          </div>
          <OtpInput
            value={otp}
            onChange={(value) => setOtp(value.toUpperCase())}
            numInputs={6}
            inputStyle={{
              width: '3rem',
              margin: 4,
              fontSize: 24
            }}
            renderInput={(props) => <input {...props} />}
          />
          <div style={{ fontFamily: 'Roboot', fontSize: 14, padding: 10, textAlign: 'center' }}>
            Não recebeu nenhum Token?
          </div>
          {otp && otp.length > 0 ? (<button onClick={() => confirmarToken()} style={{
            marginTop: 20,
            width: 120,
            backgroundColor: '#113b61',
            border: 'none',
            borderRadius: '4px',
            color: '#ffffff',
            cursor: 'pointer',
            padding: '8px',
            fontFamily: 'Roboto',
            backgroundColor: !signature ? '#96ccff' : '#113b61',
            cursor: !signature ? 'not-allowed' : 'pointer',
          }}>
            Confirmar
          </button>) : (<CountdownTimer targetDate={dateTimeAfterThreeDays} resendToken={resendToken}
          />)}
        </div>}
        {!state.isSendedToken && <>
          <div className="title-signature">
            <div style={{ fontFamily: 'Roboot', fontSize: 16 }}>
              Por favor, insira sua assinatura abaixo
            </div>
          </div>
          <div className="title-signature">
            <button
              title="Desenhar Assinatura"
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-around',
                alignItems: 'center',
                width: '8rem',
                backgroundColor: '#113b61',
                border: 'none',
                borderRadius: '4px',
                color: '#ffffff',
                cursor: 'pointer',
                fontFamily: 'Roboto',
                padding: '4px',
                paddingLeft: '8px',
                paddingRight: '8px',
              }}
              onClick={() => {
                setState((prev) => ({ ...prev, isDrawSignature: true }));
              }}
            >
              <GestureIcon />
              Desenhar
            </button>
            <button
              title="Digitar Assinatura"
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-around',
                alignItems: 'center',
                width: '8rem',
                backgroundColor: '#113b61',
                border: 'none',
                borderRadius: '4px',
                color: '#ffffff',
                cursor: 'pointer',
                fontFamily: 'Roboto',
                padding: '4px',
                paddingLeft: '8px',
                paddingRight: '8px',
              }}
              onClick={() => {
                setState((prev) => ({ ...prev, isDrawSignature: false }));
              }}
            >
              <WbAutoIcon />
              Digitar
            </button>

          </div>
          {state.isDrawSignature ? (
            <div className="signature" style={{ paddingTop: 10 }}>
              <SignatureCanvas
                penColor="#113b61"
                canvasProps={{
                  width: 500,
                  height: 120,
                }}
                onEnd={() => {
                  const _signature = sigPad.current.getTrimmedCanvas().toDataURL('image/png');
                  if (_signature.length > 120) {
                    setSignature(_signature);
                  }
                }}
                ref={sigPad}
              />
            </div>) : (
            <div className="signature">
              <Select
                fullWidth
                value={state.selectedFont}
                onChange={handleSelectClass}
              >
                {fonts.map((e) => (
                  <MenuItem key={`${e.codigo}`} value={`${e.codigo}`}>
                    <div className={`${e.codigo}`}>{toCamelCase((parts[1] === 'vendedor') ? beneficiario._vendedor.nomeFantasia : beneficiario.nomeFantasia)}</div>
                  </MenuItem>
                ))}
              </Select>
              <div
                ref={previewRef}
                className={state.selectedFont}
                style={{
                  display: "flex",
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: "100%",
                  height: '8rem',
                  fontSize: '2.5rem',
                  color: '#000',
                  textAlign: "center",
                  whiteSpace: "nowrap",
                  backgroundColor: 'transparent',
                }}
              >
                {toCamelCase((parts[1] === 'vendedor') ? beneficiario._vendedor.nomeFantasia : beneficiario.nomeFantasia)}
              </div>
              <div style={{
                backgroundColor: 'red'
              }}>
              {/* <img src={this.state.imageSignature} /> */}
              </div>

            </div>
          )}

          <div className="bar-signature">
            <button
              onClick={() => {
                if (state.isDrawSignature) clear();
              }}
              style={{
                width: 120,
                backgroundColor: '#113b61',
                border: 'none',
                borderRadius: '4px',
                color: '#ffffff',
                cursor: 'pointer',
                padding: '8px',
                fontFamily: 'Roboto',
                backgroundColor: !state.isDrawSignature ? '#96ccff' : '#113b61',
                cursor: !state.isDrawSignature ? 'not-allowed' : 'pointer',
              }}>
              Limpar
            </button>
            <button onClick={() => confirmar(beneficiario)} style={{
              width: 120,
              backgroundColor: '#113b61',
              border: 'none',
              borderRadius: '4px',
              color: '#ffffff',
              cursor: 'pointer',
              padding: '8px',
              fontFamily: 'Roboto',
              backgroundColor: !signature ? '#96ccff' : '#113b61',
              cursor: !signature ? 'not-allowed' : 'pointer',
            }}>
              Confirmar
            </button>
          </div>
        </>}

      </div >
      <DialogSignature />
      <Snackbar open={messageState} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity="error"
          variant="filled"
          sx={{ width: '100%' }}
        >
          Token informado é inválido. Verifique!
        </Alert>
      </Snackbar>
      <Modal
        isOpen={state.isSendingEmail}
        keyboard={false}
        backdrop={true}
        centered={true}
        // className="custom-modal-style"
        contentClassName="custom-modal-style"
      >
        <ModalBody style={{ backgroundColor: '#113b61', height: 107, borderRadius: 9 }}>
          <ReactLoading type={'spinningBubbles'} color={'#fff'} height={267} width={75} />
        </ModalBody>
      </Modal>
    </div >
  );
};

export default ContractSignature;