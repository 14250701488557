import React from 'react';

const DateTimeDisplay = ({ value, type, isDanger }) => {
    return (
        <div className={isDanger ? 'countdown danger' : 'countdown'}>
            {`${value}`.padStart(2, '0')}
        </div>
    );
};

export default DateTimeDisplay;