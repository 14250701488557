import React from "react";
import { FormGroup, Label, Input } from "reactstrap";
import ActionButtons from "./ActionButtons";

const PersonalData = (props) => {
    const { beneficiario, parts } = props;
    return (
        <div style={{
            padding: 20,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center'
        }}>
            <div>
                <FormGroup>
                    <Label>
                        Seja bem vindo <b>{beneficiario.nomeFantasia}</b> ao Sistema Prover Saúde.
                    </Label>
                </FormGroup>
                <FormGroup style={{ marginTop: 20 }}>
                    <Label>
                        <b>Por favor, confirme seus dados abaixo:</b>
                    </Label>
                </FormGroup>
                <FormGroup style={{ display: 'flex', flexDirection: 'column', marginTop: 10 }}>
                    <Label>Modalidade Contratada: </Label>
                    <Input
                        type="text"
                        name="age"
                        defaultValue={`${parts && parts[1] === 'liberty' ? 'SEGURO LIBERTY - ' : ''}${beneficiario._modalidade.nome}`}
                        readOnly
                    />
                </FormGroup>
                <FormGroup style={{ display: 'flex', flexDirection: 'column', marginTop: 10 }}>
                    <Label>Nome completo: </Label>
                    <Input
                        type="text"
                        name="age"
                        defaultValue={beneficiario.nomeFantasia}
                        readOnly
                    />
                </FormGroup>
                <FormGroup style={{ display: 'flex', flexDirection: 'column', marginTop: 10 }}>
                    <Label>CPF: </Label>
                    <Input
                        type="text"
                        name="age"
                        defaultValue={beneficiario.cpf}
                        readOnly
                    />
                </FormGroup>
                <FormGroup style={{ display: 'flex', flexDirection: 'column', marginTop: 10 }}>
                    <Label>Data de Nascimento: </Label>
                    <Input
                        type="text"
                        name="age"
                        defaultValue={beneficiario.dataNascimento}
                        readOnly
                    />
                </FormGroup>


                <ActionButtons {...props} />
            </div>
        </div>
    );
};

export default PersonalData;